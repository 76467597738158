
import React, { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { FaMapMarker } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ImageGallery from '../../../../components/ImageGallery';
import { simpleScrollTo } from '../../../../components/Menu';
import SliderContainer from '../../../../components/SliderContainer';
import { urlWebsite } from '../../../../config';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import SliderComponent from '../../../../pages/DashboardPages/features/SliderComponent';
import Program from '../../../../pages/Program';
import Speakers from '../../../../pages/Speakers';
import Sponsor from '../../../../pages/SponsorsPage';
import SponsorFlow from '../../../../pages/SponsorsPageFlow';
import Paginas from '../Paginas';
import {

    Capa, Container,



} from '../Themes/SinglePageTheme/styles';


const Item = ({ text }) => {
    return <div className='row' style={{ margin: '0px 7.5px', gap: '15px' }}>
        <img src="/apoio/folha.png" style={{ width: '20px', minWidth: '20px' }} />
        <h2 style={{ color: '#fff' }}>{text}</h2>
    </div>



}

const HomeApplication = () => {
    const { configuration, projectHash } = useConfiguration();
    const { translate } = useLanguage();

    const [theme, setTheme] = useState({
        titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
        textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
        buttonColor: configuration?.current_event_id_response?.theme?.button_color || 'rgb(78, 27, 129)',
        buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
    })


    useEffect(() => {
        setTheme({
            titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
            textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
            buttonColor: configuration?.current_event_id_response?.theme?.button_color || 'rgb(78, 27, 129)',
            buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
        })
    }, [configuration])

    const tape = [['TECNOLOGIA', 'AGRONEGÓCIO'],
    ['REDE TÉCNICA COOPERATIVA', 'INOVAÇÃO'],
    ['FUTURO', 'PRODUÇÃO LEITEIRA']

    ]


    return <> <Container>
        <Capa>
            <div >
                <div className='column column-start gap'>

                    <img src='/apoio/futuro.png' className='futuro' />

                    <img src='/apoio/jornada.png' style={{ maxWidth: '400px' }} />


                    {/* <button onClick={() => simpleScrollTo('programacao')} className='whiteButton scaleOnHover'>PROGRAMAÇÃO</button> */}

                    <img className='wish' src='/apoio/wish5.png' />


                    <Link className='scaleOnHover inscrevase' to="/app/jornada-rtc-2024/inscricao" >
                        <button className='blackButton'>INSCREVA-SE AGORA MESMO</button>
                    </Link>




                    {/*  <p className='paragraph max-300 computerOnly' style={{ color: "rgb(255, 255, 255)", fontSize: '14px', padding: "15px" }}> Para dúvidas ou mais informações <br />entre em contato pelo e-mail jornadartc@ccgl.com.br</p>
                    <p className='paragraph max-300 mobileOnly' style={{ color: "rgb(255, 255, 255)", fontSize: '14px', padding: "15px" }}> Para dúvidas ou mais informações entre em contato pelo e-mail jornadartc@ccgl.com.br</p> */}
                    <Sponsor />



                </div>

            </div>
            <div className=' computerOnly' ></div>




        </Capa>




    </Container>
        <Paginas hash='edicao' />

        <div className='full-width column' style={{ background: '#2b2b2b', padding: '30px 0px', gap: '30px' }}>
            <Marquee direction='right' pauseOnHover={false} gradientWidth={0} speed={40} style={{ width: '100%' }}>
                <Item text={tape[0][0]} /> <Item text={tape[0][1]} />
                <Item text={tape[0][0]} /> <Item text={tape[0][1]} />
                <Item text={tape[0][0]} /> <Item text={tape[0][1]} />
                <Item text={tape[0][0]} /> <Item text={tape[0][1]} />
                <Item text={tape[0][0]} /> <Item text={tape[0][1]} />
                <Item text={tape[0][0]} /> <Item text={tape[0][1]} />
            </Marquee>
            <Marquee pauseOnHover={false} gradientWidth={0} speed={20} style={{ width: '100%' }}>
                <Item text={tape[1][0]} /> <Item text={tape[1][1]} />
                <Item text={tape[1][0]} /> <Item text={tape[1][1]} />
                <Item text={tape[1][0]} /> <Item text={tape[1][1]} />
                <Item text={tape[1][0]} /> <Item text={tape[1][1]} />
                <Item text={tape[1][0]} /> <Item text={tape[1][1]} />
                <Item text={tape[1][0]} /> <Item text={tape[1][1]} />
                <Item text={tape[1][0]} /> <Item text={tape[1][1]} />
            </Marquee>
            <Marquee direction='right' pauseOnHover={false} gradientWidth={0} speed={30} style={{ width: '100%' }}>
                <Item text={tape[2][0]} /> <Item text={tape[2][1]} />
                <Item text={tape[2][0]} /> <Item text={tape[2][1]} />
                <Item text={tape[2][0]} /> <Item text={tape[2][1]} />
                <Item text={tape[2][0]} /> <Item text={tape[2][1]} />
                <Item text={tape[2][0]} /> <Item text={tape[2][1]} />
                <Item text={tape[2][0]} /> <Item text={tape[2][1]} />
                <Item text={tape[2][0]} /> <Item text={tape[2][1]} />
            </Marquee>

        </div>

        <Paginas hash='a-rtc' />
        {/*    <Speakers />
        <Program />

                */}

        <Paginas hash='local' />
        <div className='column' style={{ background: 'rgb(255, 255, 255)', minHeight: '40vh', width: '100%' }}>
            <div className='row-to-column ' style={{ maxWidth: '1200px' }}>
                <Paginas hash='hoteis' />
                <Paginas hash='restaurantes' />
            </div>
        </div>
    </>

}

export default HomeApplication;